import * as React from 'react'
import NavHeader from '../../components/navHeader'
import SiteFooter from '../../components/footer'
import PageTitle from '../../components/pageTitle'
import { Link } from 'gatsby'
import * as eGovernmentStyle from '../../styles/eGovernment.module.scss'
import * as navHeaderStyle from '../../styles/navHeader.module.scss'

const EGovernment = () => {
  return (
    <main>
    <NavHeader activeClassName={navHeaderStyle.active} partiallyActive={true}/>
    <PageTitle pageTitle="eGovernment"/>
        <div className="mainContainer">
        <div class="egovernment-container">
                <section className={eGovernmentStyle.egovSection}>
                    <div class="section-container">
                        <div className={eGovernmentStyle.articleHeading}>
                        <Link to="../../eGovernment"></Link>
                            <h1>Systém pre dôveryhodné doručovanie</h1>    
                        </div>
                        <article className={eGovernmentStyle.articleText}>
                            <h2>Východiská</h2>
                            <p>V súčasnosti veľká časť populácie používa pri komunikácii elektronické prostriedky
                                vrátane
                                komunikácie prostredníctvom elektronickej pošty. Občania a právnické subjekty preto
                                očakávajú takýto spôsob komunikácie aj s verejnou správou. Komunikácia s verejnou
                                správou je
                                však v mnohých prípadoch formalizovaná a preto je nevyhnutné, aby bola zabezpečená
                                s ohľadom
                                na takéto procesy. Príkladom môže byť povinnosť podávania podaní do určeného času,
                                zaručenie
                                identity podávajúceho, istota doručenia, nespochybniteľné zaznamenanie času prevzatia
                                zásielky a podobne.</p>
                            <p>Na druhej strane existuje stále veľká skupina populácie, ktorá preferuje osobný kontakt,
                                nie
                                je ochotná podávať podania elektronicky a požaduje, aby dôležité dokumenty od verejnej
                                správy jej boli zasielane v listinnej podobe.</p>
                        </article>
                        <article className={eGovernmentStyle.articleText}>
                            <h2>Riešenie</h2>
                            <p>Vybudovanie elektronických schránok, prostredníctvom ktorých komunikuje verejná sprava
                                smerom
                                na občanov a umožňuje aj komunikáciu občanov na verejnú správu. Riešenie obsahuje systém
                                potvrdzovania uloženia dokumentov do schránky inštitúcie (potvrdenia o podaní na
                                inštitúciu)
                                vrátane zaznamenania dátumu a času podania. Taktiež obsahuje proces preberania
                                doručovaných
                                zásielok pre verejnosť a podnikateľský sektor.</p>
                            <p>Aby inštitúcie nemuseli riešiť organizovanie zasielania listinných zásielok, tento systém
                                je
                                rozšírený o systém Centrálneho úradného doručovania (CÚD).</p>
                            <p>V prípade využívania komponentu CÚD každá inštitúcia posiela a doručuje svoje dokumenty
                                výhradne do elektronických schránok. V prípade, že majiteľ schránky požaduje takéto
                                zásielky
                                zasielať v listinnej podobe, tak elektronické dokumenty sú smerovane na centrálny
                                systém,
                                ktorý prevedie elektronickú podobu do listinnej, zabezpečí vytlačenie a doručenie. Po
                                doručení zašle naspäť informáciu o spôsobe a čase doručenia. Všetky doručované dokumenty
                                sú
                                zároveň ukladané do centrálneho registra úradných dokumentov, aby v prípade pochybnosti
                                o
                                pravosti dokumentu bolo možné jeho overenie v tomto registri. V rozšírenej verzii je
                                možné
                                za prísnych bezpečnostných pravidiel umožniť prístup k tomuto registru aj jednotlivým
                                inštitúciám, aby nemuseli požadovať od verejnosti dokumenty, ktoré vydali iné inštitúcie
                            </p>
                        </article>
                        <article className={eGovernmentStyle.articleText}>
                            <h2>Výhody riešenia</h2>
                            <h3>Občania:</h3>
                            <ul>
                                <li>Umožnenie dôveryhodnej komunikácie nonstop</li>
                                <li>Zrýchlenie konania</li>
                                <li>Zavedenie princípu “jedenkrát a dosť”, t.j. občan alebo podnikateľský subjekt nemusí
                                    nosiť dokumenty vydané jednou inštitúciou na inú inštitúciu</li>
                            </ul>
                            <h3>Úradníci:</h3>
                            <ul>
                                <li>Odbremenenie úradov (úradníkov) od prác spojených s vyhotovovaním listinných
                                    zásielok
                                </li>
                            </ul>
                        </article>
                    </div>
                </section>
            </div>
        </div>
    <SiteFooter/>
    </main>
  )
}

export default EGovernment